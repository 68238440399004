import React, { createContext, useState, useEffect, useMemo } from 'react';

const DEFAULT_STATE = {
  favourites: [],
  clearFavourites: () => {},
  removeFromFavourites: () => {},
  checkIsFavourite: () => false,
  handleFavOption: () => {},
  removeFavourites: () => {},
};

export const FavouritesContext = createContext(DEFAULT_STATE);

const FavouritesProvider = ({ children }) => {
  const [favourites, setFavourites] = useState([]);

  const updateFavourites = newFavs => {
    localStorage.setItem('favourites-domaniewska', JSON.stringify(newFavs));
    setFavourites(newFavs);
  };

  const removeFavourites = () => {
    localStorage.setItem('favourites-domaniewska', JSON.stringify([]));
    setFavourites([]);
  };

  const addToFavourites = flat => {
    updateFavourites([...favourites, flat]);
  };

  const removeFromFavourites = flat => {
    const elementToRemove = favourites.findIndex(
      ({ name, investment }) =>
        flat.name === name && investment === flat.investment
    );
    updateFavourites(
      favourites.filter((_, index) => index !== elementToRemove)
    );
  };

  const checkIsFavourite = flat => {
    if (favourites.length !== 0) {
      return favourites.some(
        ({ name, investment }) =>
          flat.name === name && investment === flat.investment
      );
    }
    return false;
  };

  const handleFavOption = (flat, addOnly = false) => {
    if (checkIsFavourite(flat)) {
      if (!addOnly) removeFromFavourites(flat);
      return;
    }

    //@ts-ignore
    if (window.dataLayer) {
      //@ts-ignore
      window.dataLayer.push({
        event: 'add_to_wishlist',
        //@ts-ignore
        mieszkanie: flat.number,
      });
    }

    addToFavourites(flat);
  };

  const clearFavourites = () => {
    updateFavourites([]);
  };

  useEffect(() => {
    const localFavs =
      typeof window !== `undefined`
        ? JSON.parse(localStorage.getItem('favourites-domaniewska') ?? '[]')
        : [];
    setFavourites(localFavs);
  }, []);

  const providerValues = useMemo(
    () => ({
      favourites,
      clearFavourites,
      removeFromFavourites,
      checkIsFavourite,
      handleFavOption,
      removeFavourites,
    }),
    [
      favourites,
      clearFavourites,
      removeFromFavourites,
      checkIsFavourite,
      handleFavOption,
      removeFavourites,
    ]
  );

  return (
    <FavouritesContext.Provider value={providerValues}>
      {children}
    </FavouritesContext.Provider>
  );
};

export default FavouritesProvider;
