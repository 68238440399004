const breakpoints = {
  xs: 440,
  s: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};

export const theme = {
  fonts: {
    mainFont: `'Public Sans', sans-serif`,
    subFont: `'Kumbh Sans', sans-serif`,
    // specialFont: `'Reenie Beanie', sans-serif`,
  },
  colors: {
    white: '#ffffff',
    dark: '#182a38',
    darkBackground: '#192a3a',
    gray: '#646363',
    gray100: '#dcdcdc',
    gray200: '#EEEEEE',
    gray300: '#BFBFBF',
    red: '#E30613',
    red100: '#f93d5c',
    red200: '#f44336',
    green: '#09ab9b',
    green100: '#57c766',
    green200: '#09ab9b',
    lightGreen: '#dbdd3c',
    lightGreen100: '#9acf34',
    lightBlue: '#dceff3',
    lightYellow: '#eaf2da',
    available: '#3ba440',
    reserved: '#fcb21f',
    sold: '#e81d25',
  },
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  fontSize: {
    xs: '1.1rem',
    s: '1.4rem',
    m: '1.6rem',
    md: '1.9rem',
    lg: '2.1rem',
    xl: '2.6rem',
  },
  navSize: {
    desktop: '110px',
    mobile: '80px',
  },
  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;
    return acc;
  }, {}),
};
