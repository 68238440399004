/* eslint-disable no-use-before-define */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import useOutsideClick from 'hooks/useOutsideClick';
import Links from 'components/Navigation/Links';
import Logo from 'components/shared/Logo';
import Icon from 'components/shared/Icon';
import closeIcon from 'assets/icons/close.svg';
import investmentLogo from 'assets/images/nav-logo.svg';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.35);
  z-index: 101;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      & > ${Wrapper} {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }
    `}
`;

const Wrapper = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 14;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  will-change: transform;
  transition: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 2px 0 10px rgba(54, 54, 54, 0.2),
    -2px 0 10px rgba(54, 54, 54, 0.2);
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.dark};
  transform: translateX(100vw);
  ${({ theme }) => theme.mq.s} {
    width: 440px;
    transform: translateX(440px);
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
`;

const CloseBtn = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: auto;
`;

const IconWrapper = styled.a`
  display: block;
  width: 180px;
  margin: 25px auto 0;
`;

const Sidenav = ({ isVisible, close }) => {
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, close);

  return (
    <Overlay isVisible={isVisible}>
      <Wrapper ref={wrapperRef}>
        <CloseBtn onClick={close}>
          <Icon size={20} src={closeIcon} alt="close" />
        </CloseBtn>
        <InnerWrapper>
          <Logo big />
          <Links close={close} />
          <IconWrapper href="/">
            <Icon src={investmentLogo} alt="domaniewska logo" full />
          </IconWrapper>
        </InnerWrapper>
      </Wrapper>
    </Overlay>
  );
};

Sidenav.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default Sidenav;
